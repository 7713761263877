import * as React from 'react';
import './Spinner.css';

export default function Spinner() {
  return (
    <div className="wrapper">
      <div className="spinner"></div>
    </div>
  );
}
